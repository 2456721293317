










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    PInsFooterSwiper: () => import('./layout/pc/InsFooterSwiper.vue'),
    MInsFooterSwiper: () => import('./layout/mobile/InsFooterSwiper.vue'),
    PInsFooterLayout: () => import('./layout/pc/InsFooterLayout.vue'),
    MInsFooterLayout: () => import('./layout/mobile/InsFooterLayout.vue')
    // footerLayout: () => import('./layout/pc/InsFooterLayout.vue')
  }
})
export default class InsFooter extends Vue {
  footerContact: object[] = [];
  getFooterContact () {
    this.$Api.cms
      .getCategoryByDevice({ CatId: 40112, IsMobile: this.isMobile })
      .then(result => {
        if (result.Children && result.Children.length > 4) {
          this.footerContact = [result.Children[3], result.Children[0], result.Children[1], result.Children[2], result.Children[5]];
        }
        console.log(this.footerContact, 'footer联系方式');
      });
  }
  get isMobile () {
    return this.$store.state.isMobile;
  }
  mounted () {
    this.getFooterContact();
  }
}
